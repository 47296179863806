import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../components/PageNotFound.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(VueRouter, BootstrapVue, IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projet',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/actus',
    name: 'News',
    component: () => import('../views/Actus.vue')
  },
  { 
    name: 'Article',
    path: '/article/:id', 
    component: () => import('../views/Article.vue')
  },
  { 
    name: 'Participate',
    path: '/participons', 
    component: () => import('../views/Participate.vue')
  },
  { 
    name: 'Management',
    path: '/participez/gestion', 
    component: () => import('../views/Management.vue')
  },
  { 
    name: 'Resources',
    path: '/ressources', 
    component: () => import('../views/Resources.vue')
  },
  { 
    name: 'Contact',
    path: '/contact', 
    component: () => import('../views/Contact.vue')
  },
  { 
    name: 'Privacy',
    path: '/confidentialite', 
    component: () => import('../views/Privacy.vue')
  },
  { 
    name: 'Legal',
    path: '/mentions-legales', 
    component: () => import('../views/Legal.vue')
  },
  { path: '/404', component: PageNotFound },  
  { path: '*', redirect: '/404' },  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
