<template>
  <div id="home">
    <b-row>
      <b-col cols="12" md="6">
        <Slider />
      </b-col>
      <b-col cols="12" md="6" id="actus">
        <p>A la une</p>
        <Loader v-if="loader" class="loader-little" />
        <ArticleFront id="actu_home" v-else v-bind:article="article" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {
    Loader: () => import("../components/Loader.vue"),
    ArticleFront: () => import("../components/ArticleFront.vue"),
    Slider: () => import("../components/Slider.vue"),
  },
  name: "Home",
  data() {
    return {
      article: "null",
      loader: true,
    };
  },
  beforeCreate() {
    this.$http
      .get(
        "https://api.opci-ethnodoc.fr/wp-json/wp/v2/posts?categories=2&per_page=1"
      )
      .then(
        (response) => ((this.article = response.data[0]), (this.loader = false))
      );
  },
};
</script>

<style>
#actus {
  background-color: #68bec5;
  color: white;
  padding: 25px;
}

#actu_home {
  border: solid 3px #bee3e6;
  background-color: #bee3e6;
}

#actu_home img {
  width: 15vw;
}

@media (max-width: 768px) {
  #actus {
    margin-top: 10px;
  }
}
</style>